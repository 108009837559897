* {
  box-sizing: border-box;
}

input {
  width: 80%;
  margin: 1rem auto;
  display: block;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.accessCourse:hover {
  background: darkred !important;
}

.pdfLink {
  color: black;
  margin-top: 1.5rem;
}

.backLink:hover {
  color: darkred !important;
  text-decoration: underline !important;
}

.pdf-container {
  border: 1px solid black;
  margin: 1rem 0;
}

a {
  width: fit-content;
}

@media print {
  .pdf-container {
    display: none;
  }
}

@media only screen and (max-width: 300px) {
  h2 {
    font-size: 1rem !important;
  }

  .logout {
    font-size: 0.7rem !important;
  }
}
